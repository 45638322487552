import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import React from 'react'

const PreviewItem = ({ item }) => {
  return (
    <a href={`/case-study/${item.slug}`}>
      <div
        key={item.title}
        className="flex flex-col overflow-hidden rounded-lg shadow-lg h-132"
      >
        <div className="flex-shrink-0 rounded">
          <GatsbyImage
            alt={`${item.title} hero image`}
            image={item.heroImage.gatsbyImage}
            className="h-48 w-full object-cover rounded-t-lg"
          />
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <p className="text-xl font-semibold text-ufblack line-clamp-3">
              {item.title}
            </p>
            <span className="mt-3 text-base text-gray-500 line-clamp-4">
              {item.description?.raw && renderRichText(item.description)}
            </span>
          </div>
          <div className="mt-6 flex items-center">
            <div className="flex space-x-1 text-sm text-uforange-600 font-bold">
              <span>{item.localAuthority}</span>
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export default PreviewItem
