import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import PreviewItem from '../components/casestudies/PreviewItem'
import Header from '../components/common/Header'
import AppCTA from '../components/cta/AppCTA'
import GeneralCTA from '../components/cta/GeneralCTA.js'
import NewsletterCTA from '../components/cta/NewsletterCTA.js'
import Layout from '../components/layout'
import Seo from '../components/seo'

class BlogIndex extends React.Component {
  render() {
    const data = get(this, 'props.data.allContentfulCaseStudy.nodes')

    if (!data) return null
    if (!Array.isArray(data)) return null

    return (
      <Layout location={this.props.location}>
        <Seo
          title="Case Studies | Urban Fox | EV Charging Network"
          description="Examples and Case studies of the Urban Fox network being deployed across the UK."
        />
        <Header filter="Case Studies" />
        <div className="relative mx-auto max-w-7xl">
          <div className="mx-auto my-24 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
            {data.map((study) => (
              <PreviewItem item={study} />
            ))}
          </div>
        </div>
        <NewsletterCTA />
        <AppCTA />
        <GeneralCTA />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query CaseStudyIndexQuery {
    allContentfulCaseStudy(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        localAuthority
        publishDate(formatString: "MMMM Do, YYYY")
        rawDate: publishDate
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        body {
          raw
        }
        description {
          raw
        }
      }
    }
  }
`
